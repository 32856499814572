import axios from "axios";
import { useState } from "react";
// constantes
const dataInicial = {
  properties: [''],
  property: {},
  featured:[],
  favorites: [],
  loading: true,
  loading_more:false,
  filters: {
    location: [],
    type: [],
    operation: "",
    environments: [],
    price: [],
    surface: [],
    environments_types: [],
    generals: [],
    services: [],
    specials: [],
  },
  orders: {
    order: "desc",
    order_by: "id",
  },
  totalListFilters: {
    location: [],
    type: [],
    operation: [],
    environments: [],
    price: [],
    surface: [],
    environments_types: [],
    generals: [],
    services: [],
    specials: [],
  },
  listFilters: {
    location: [],
    type: [],
    operation: [],
    environments: [],
    price: [],
    surface: [],
    environments_types: [],
    generals: [],
    services: [],
    specials: [],
  },
  offset: 12,
  totalCount:0
};


// types
const LOADING = "LOADING";
const LOADING_MORE = "LOADING_MORE";
const CHANGE_TYPE = "CHANGE_TYPE";
const UPDATE_FILTERS = "UPDATE_FILTERS";
const UPDATE_ORDERS = "UPDATE_ORDERS";
const LIST_FILTERS = "LIST_FILTERS";
const TOTAL_LIST_FILTERS = "TOTAL_LIST_FILTERS";
const GET_PROPERTY = "GET_PROPERTY";
const GET_FAV_PROPERTY = "GET_FAV_PROPERTY";
const CLEAR_FAV_PROPERTY = "CLEAR_FAV_PROPERTY";
const DELETE_FAV_PROPERTY = "DELETE_FAV_PROPERTY";
const GET_PROPERTIES = "GET_PROPERTIES";
const GET_FULL_PROPERTIES = "GET_FULL_PROPERTIES";
const GET_MORE_PROPERTIES = "GET_MORE_PROPERTIES";
const GET_FEATURED_PROPERTIES = "GET_FEATURED_PROPERTIES";
const CLEAR_PROPERTIES = "CLEAR_PROPERTIES";

const GET_TOTALCOUNT = "GET_TOTALCOUNT";

// reducer
export default function propertiesReducer(state = dataInicial, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true };
    case LOADING_MORE:
      return { ...state, loading_more: true };
    case CHANGE_TYPE:
      return { ...state, listed: action.payload };
    case UPDATE_FILTERS:
      return { ...state, filters: action.payload };
    case UPDATE_ORDERS:
      return { ...state, orders: action.payload };
    case TOTAL_LIST_FILTERS:
      return { ...state, totalListFilters: action.payload };
    case LIST_FILTERS:
      return { ...state, listFilters: action.payload };
    case GET_FULL_PROPERTIES:
      return { ...state, fullProperties: action.payload, loading: false };
    case GET_PROPERTY:
      return { ...state, property: action.payload.data, loading: false };
    case GET_FAV_PROPERTY:
      return {
        ...state,
        favorites: [...state.favorites, action.payload.data],
        loading: false,
      };
    case DELETE_FAV_PROPERTY:
      return {
        ...state,
        favorites: [...state.favorites, action.payload],
        loading: false,
      };
    case CLEAR_FAV_PROPERTY:
      return { ...state, favorites: [], loading: false };
    case GET_PROPERTIES:
      return { ...state,
        properties: action.payload.data.objects,
        totalCount: action.payload.data.meta.total_count,
        loading:false };
    case GET_MORE_PROPERTIES:
      return {
        ...state,
        properties: action.payload,
        offset: state.offset + 12,
        loading_more: false,
      };
    case GET_FEATURED_PROPERTIES:
      return { ...state, featured: action.payload,loading:false };
    case CLEAR_PROPERTIES:
      return { ...state, properties: [], loading: false };
    default:
      return state;
  }
}
// actions
export const changeTypeList = (type) => (dispatch, getState) => {
  dispatch({
    type: CHANGE_TYPE,
    payload: type,
  });
};
export const getTotalListFilters = () => async (dispatch, getState) => {
  const summary_filters = (JSON.stringify({
    "current_localization_id":[], 
    "current_localization_type": "",
    // "currency":"",
    "price_from":0,
    "price_to":9999999999999,
    "operation_types":[1,2,3],
    "property_types":[]
  }))
    try {
      const res = await axios.get(
        "https://tokkobroker.com/api/v1/property/get_search_summary/?key=" +
          getState().settings.keys.tokko + 
          "&lang=es_ar&format=json&data=" + summary_filters
        );
      dispatch({
        type: TOTAL_LIST_FILTERS,
        payload: res,
      });
    } catch (error) {
      console.log(error);
    }
};
export const getListFilters = () => async (dispatch, getState) => {
  const filters = getState().properties.filters;
  
  const operation_id = [
    { name: "venta", id: 1 },
    { name: "alquiler", id: 2 },
    { name: "alquiler temporario", id: 3 },
    { name: "alquiler-temporario", id: 3 },
  ];
  const operation = filters.operation ? [operation_id.find((element) => element.name === filters.operation.toLowerCase()).id] : [];
  const types = filters.type ? filters.type.map(element => element.id) : [];
  const locations = filters.location ? filters.location.map(element => element.id) : [];
  const currency = filters.price.type === 'dolares' ? 'USD' : filters.price.type === 'pesos' ? 'ARS' : '';
  const price_from = filters.price.priceMin ? filters.price.priceMin : 0;
  const price_to = filters.price.priceMax ? filters.price.priceMax : 0;
  if(operation){
    const summary_filters = (JSON.stringify({
      "current_localization_id":locations, 
      "current_localization_type": locations.length > 0 ? 'division' : '',
      // "currency":currency,
      "price_from":price_from,
      "price_to":price_to,
      "operation_types":operation,
      "property_types":types
    }))
    try {
      const res = await axios.get(
        "https://tokkobroker.com/api/v1/property/get_search_summary/?key=" +
          getState().settings.keys.tokko + 
          "&lang=es_ar&format=json&data=" + encodeURI(summary_filters)
        );
      dispatch({
        type: LIST_FILTERS,
        payload: res,
      });
    } catch (error) {
      console.log(error);
    }
  }
};
// Filters
export const REDUX_UPDATE_FILTERS = (_filters) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_FILTERS,
    payload: _filters,
  });
};
export const redux_updateOrders = (orders) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_ORDERS,
    payload: orders,
  });
};
// Properties
export const getPropertyAction = (id) => async (dispatch, getState) => {
  dispatch({type: LOADING});
  try {
    const res = await axios.get(
      " https://api-mh.mediahaus.com.ar/api/v1/tokko/properties/" +
        id +
        "?cache=true",
      {
        headers: {
          "X-TOKKO-KEY": getState().settings.keys.tokko
        },
      }
    );
    dispatch({
      type: GET_PROPERTY,
      payload: res,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getFavoritesAction = (id) => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
  });
  if (
    getState().properties.favorites.find(
      (element) => element.data.id.toString() === id.toString()
    )
  ) {
    const new_favs = getState().properties.favorites.filter(
      (element) => element.data.id.toString() !== id.toString()
    );
    dispatch({
      type: DELETE_FAV_PROPERTY,
      payload: new_favs,
    });
  }
  try {
    const res = await axios.get(
      " https://api-mh.mediahaus.com.ar/api/v1/tokko/properties/" +
        id +
        "?cache=true",
      {
        headers: {
          "X-TOKKO-KEY": getState().settings.keys.tokko
        },
      }
    );
    dispatch({
      type: GET_FAV_PROPERTY,
      payload: res,
    });
  } catch (error) {
    console.log(error);
  }
};
export const clearFavoritesAction = (id) => async (dispatch, getState) => {
  dispatch({
    type: CLEAR_FAV_PROPERTY,
  });
};
export const clearPropertiesAction = (id) => async (dispatch, getState) => {
  dispatch({
    type: CLEAR_PROPERTIES,
  });
};
export const getPropertiesAction = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
  });
  const filters = getState().properties.filters;
  const orders = getState().properties.orders;
  var params_get = "";
  var tags_both = [];
  const operation_id = [
    { name: "venta", id: 1 },
    { name: "alquiler", id: 2 },
    { name: "alquiler temporario", id: 3 },
    { name: "alquiler-temporario", id: 3 },
  ];
  const environments = [
    { name: "1 ambiente", id: 1 },
    { name: "2 ambientes", id: 2 },
    { name: "3 ambientes", id: 3 },
    { name: "4 ambientes", id: 4 },
    { name: "5 ambientes", id: 5 },
    { name: "6 ambientes", id: 6 },
  ];
  if (filters.operation) {
    params_get += "&operation=" + operation_id.find((element) => element.name === filters.operation.toLowerCase()).id;
  }
  if (filters.type.length > 0) {
    params_get += "&tipo=" + filters.type.map((element) => element.id);
  }
  if (filters.location.length > 0) {
    params_get +=
      "&localization-divisions=" +
      filters.location.map((element) => element.id) +
      "&localization-type=division";
  }
  if (filters.services.length > 0) {
    tags_both.push(filters.services.map((element) => element.id));
  }
  if (filters.generals.length > 0) {
    tags_both.push(filters.generals.map((element) => element.id));
  }
  if (filters.environments_types.length > 0) {
    tags_both.push(filters.environments_types.map((element) => element.id));
  }
  if (filters.environments_types.length > 0 ||filters.generals.length ||filters.services.length > 0) {
    params_get += "&tags=" + tags_both;
  }
  if (filters.surface.type) {
    var type_surface = "";
    const surfaceMin = filters.surface.surfaceMin ? filters.surface.surfaceMin : 0;
    const surfaceMax = filters.surface.surfaceMax ? filters.surface.surfaceMax : 999999999;
    if (filters.surface.type.toLowerCase() == "cubierta") {
      type_surface = "roofed";
    } 
    else{
      type_surface = filters.surface.type;
    } 
    params_get +=
      "&surface-type=" +
      type_surface +
      "&surface=" +
      surfaceMin+
      "-" +
      surfaceMax;
  }
  if (filters.price.type) {
    var price_type = "";
    switch (filters.price.type) {
      case "pesos": {
        price_type = "ars";
        break;
      }
      default:
        price_type = "usd";
    }

    const priceMin = filters.price.priceMin ? filters.price.priceMin : 0;
    const priceMax = filters.price.priceMax ? filters.price.priceMax : 10000000;

    params_get +=
      "&currency=" +
      price_type +
      "&price=" +
      priceMin +
      "-" +
      priceMax
  }
  if (filters.environments.length > 0) {
    var curenvironmets = [];
    for (const iterator of filters.environments) {
      curenvironmets.push(environments.find((element) => element.name === iterator.toLowerCase()).id
      );
    }
    params_get += "&rooms=" + curenvironmets[0];
  }
  try {
    const res = await axios.get(
      " https://api-mh.mediahaus.com.ar/api/v1/tokko/properties/search?cache=false&save=false&limit=12&offset=0&order-by=" +
        orders.order_by +
        "&order=" +
        orders.order +
        "" +
        params_get,
      {
        headers: {
          // "X-TOKKO-KEY": getState().settings.keys.tokko,
          "X-TOKKO-KEY": getState().settings.keys.tokko
        },
      }
    );
    dispatch({
      type: GET_PROPERTIES,
      payload: res,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getFullPropertiesAction = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
  });
  try {
    const res = await axios.get(
      " https://api-mh.mediahaus.com.ar/api/v1/tokko/properties/search?cache=false&save=false&limit=36&offset=0",
      {
        headers: {
          "X-TOKKO-KEY": getState().settings.keys.tokko
        },
      }
    );
    dispatch({
      type: GET_FULL_PROPERTIES,
      payload: res,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getAdd_PropertiesAction = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING_MORE,
  });
  const filters = getState().properties.filters;
  const orders = getState().properties.orders;
  var params_get = "";
  var tags_both = [];
  const operation_id = [
    { name: "venta", id: 1 },
    { name: "alquiler", id: 2 },
    { name: "alquiler temporario", id: 3 },
    { name: "alquiler-temporario", id: 3 },
  ];
  const environments = [
    { name: "1 ambiente", id: 1 },
    { name: "2 ambientes", id: 2 },
    { name: "3 ambientes", id: 3 },
    { name: "4 ambientes", id: 4 },
    { name: "5 ambientes", id: 5 },
    { name: "6 ambientes", id: 6 },
  ];
  if (filters.operation) {
    params_get += "&operation=" + operation_id.find((element) => element.name === filters.operation.toLowerCase()).id;
  }
  if (filters.type.length > 0) {
    params_get += "&tipo=" + filters.type.map((element) => element.id);
  }
  if (filters.location.length > 0) {
    params_get +=
      "&localization-divisions=" +
      filters.location.map((element) => element.id) +
      "&localization-type=division";
  }
  if (filters.services.length > 0) {
    tags_both.push(filters.services.map((element) => element.id));
  }
  if (filters.generals.length > 0) {
    tags_both.push(filters.generals.map((element) => element.id));
  }
  if (filters.environments_types.length > 0) {
    tags_both.push(filters.environments_types.map((element) => element.id));
  }
  if (filters.environments_types.length > 0 ||filters.generals.length ||filters.services.length > 0) {
    params_get += "&tags=" + tags_both;
  }
  if (filters.surface.type) {
    var type_surface = "";
    const surfaceMin = filters.surface.surfaceMin ? filters.surface.surfaceMin : 0;
    const surfaceMax = filters.surface.surfaceMax ? filters.surface.surfaceMax : 999999999;
    if (filters.surface.type.toLowerCase() == "cubierta") {
      type_surface = "roofed";
    } 
    else{
      type_surface = filters.surface.type;
    } 
    params_get +=
      "&surface-type=" +
      type_surface +
      "&surface=" +
      surfaceMin+
      "-" +
      surfaceMax;
  }
  if (filters.price.type) {
    var price_type = "";
    switch (filters.price.type) {
      case "pesos": {
        price_type = "ars";
        break;
      }
      default:
        price_type = "usd";
    }

    const priceMin = filters.price.priceMin ? filters.price.priceMin : 0;
    const priceMax = filters.price.priceMax ? filters.price.priceMax : 10000000;

    params_get +=
      "&currency=" +
      price_type +
      "&price=" +
      priceMin +
      "-" +
      priceMax
  }
  if (filters.environments.length > 0) {
    var curenvironmets = [];
    for (const iterator of filters.environments) {
      curenvironmets.push(environments.find((element) => element.name === iterator.toLowerCase()).id
      );
    }
    params_get += "&rooms=" + curenvironmets[0];
  }
  try {
    const res = await axios.get(
      "https://api-mh.mediahaus.com.ar/api/v1/tokko/properties/search?cache=false&save=false&limit=12&offset=" +
        getState().properties.offset +
        "&order-by=" +
        orders.order_by +
        "&order=" +
        orders.order +
        params_get,
      {
        headers: {
          "X-TOKKO-KEY": getState().settings.keys.tokko
        },
      }
    );
    var response = getState().properties.properties;
    for (const iterator of res.data.objects) {
      response.push(iterator);
    }
    dispatch({
      type: GET_MORE_PROPERTIES,
      payload: response,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getFeaturedPropertiesAction = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
  });
  try {
    const res = await axios.get(
      "https://api-mh.mediahaus.com.ar/api/v1/tokko/properties/search?cache=false&save=false&limit=24&offset=0&destacados=true",
      {
        headers: {
          "X-TOKKO-KEY": getState().settings.keys.tokko,
        },
      }
    );
    dispatch({
      type: GET_FEATURED_PROPERTIES,
      payload: res,
    });
  } catch (error) {
    console.log(error);
  }
};