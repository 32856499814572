import {createStore, combineReducers, compose, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'

import app from './app';
import propDucks from './propertiesDucks'
import settingsDucks from './settingsDucks'
import developmentDucks from './developmentsDucks'
import noveltiesDucks from './noveltiesDucks'
import contactDucks from './contactDucks'
import actionsDucks from './actionsDucks'

export default combineReducers({ 
    app,
    properties:propDucks,
    settings:settingsDucks,
    developments:developmentDucks,
    novelties:noveltiesDucks,
    actions:actionsDucks,
    contact:contactDucks,
 });